/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
import {getScrollTriggers, setScrollTrigger} from "utils/scrolltriggers";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    strong: "strong",
    ul: "ul",
    li: "li",
    a: "a"
  }, _provideComponents(), props.components), {ResourcePanel, Pin, InfoNugget, InfoItem, Headline, CareInstructions, CareItem} = _components;
  if (!CareInstructions) _missingMdxReference("CareInstructions", true);
  if (!CareItem) _missingMdxReference("CareItem", true);
  if (!Headline) _missingMdxReference("Headline", true);
  if (!InfoItem) _missingMdxReference("InfoItem", true);
  if (!InfoNugget) _missingMdxReference("InfoNugget", true);
  if (!Pin) _missingMdxReference("Pin", true);
  if (!ResourcePanel) _missingMdxReference("ResourcePanel", true);
  return React.createElement(React.Fragment, null, React.createElement(ResourcePanel, {
    id: "figure01",
    image: "figure01",
    imageAlt: "lorem ipsum",
    roundTopCorners: true,
    slug: props.data.mdx.frontmatter.slug,
    getTriggers: getScrollTriggers
  }, React.createElement(Pin, {
    id: "figure01",
    type: "start",
    height: 200,
    setTrigger: setScrollTrigger
  }), React.createElement(InfoNugget, null, React.createElement(_components.p, null, React.createElement(_components.strong, null, "Lasers"), " are often utilized to treat a number of dermatologic conditions and are safe in black skin."), React.createElement(_components.p, null, "Laser treatments function by directing a beam of photons, or particles of light energy, into the skin.")), React.createElement(InfoNugget, null, React.createElement(_components.p, null, "The doctor controls the properties of the beam so that the photons interact with specific chromophores."), React.createElement(_components.p, null, React.createElement(_components.strong, null, "Chromophores are materials present in the tissue that absorb different wavelengths of light"), ", allowing the chromophores to be targeted, heated, and destroyed without damaging the surrounding structures."), React.createElement(_components.p, null, "The most important chromophores in laser treatments of the skin are"), React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "\n", React.createElement(InfoItem, null, "melanin"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(InfoItem, null, "water"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(InfoItem, {
    noBorder: true
  }, "hemoglobin"), "\n"), "\n")), React.createElement(Pin, {
    id: "figure01",
    type: "end",
    height: 200,
    setTrigger: setScrollTrigger
  })), "\n", React.createElement(ResourcePanel, {
    id: "figure02",
    image: "figure02",
    imageAlt: "lorem ipsum",
    slug: props.data.mdx.frontmatter.slug,
    getTriggers: getScrollTriggers,
    slug: props.data.mdx.frontmatter.slug
  }, React.createElement(Pin, {
    id: "figure02",
    type: "start",
    height: 400,
    setTrigger: setScrollTrigger
  }), React.createElement(InfoNugget, null, React.createElement(Headline, {
    level: 2
  }, "Melanin"), React.createElement(_components.p, null, "Melanin is the target chromophore for treating"), React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "\n", React.createElement(InfoItem, null, React.createElement(_components.a, {
    href: "/sun-damage"
  }, "sun spots")), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(InfoItem, null, "age spots"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(InfoItem, null, React.createElement(_components.a, {
    href: "/hyperpigmentation/"
  }, "hyperpigmentation")), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(InfoItem, null, "removing hair"), "\n"), "\n"), React.createElement(_components.p, null, "This can be tricky in individuals with more melanin-rich skin."), React.createElement(_components.p, null, "Extra care must be taken to not damage the melanin of the individual's natural skin tone.")), React.createElement(Pin, {
    id: "figure02",
    type: "end",
    height: 400,
    setTrigger: setScrollTrigger
  })), "\n", React.createElement(ResourcePanel, {
    id: "figure03",
    image: "figure03",
    imageAlt: "lorem ipsum",
    slug: props.data.mdx.frontmatter.slug,
    getTriggers: getScrollTriggers,
    slug: props.data.mdx.frontmatter.slug
  }, React.createElement(Pin, {
    id: "figure03",
    type: "start",
    height: 400,
    setTrigger: setScrollTrigger
  }), React.createElement(InfoNugget, null, React.createElement(Headline, {
    level: 2
  }, "Water"), React.createElement(_components.p, null, "Water in the dermis is the target chromophore for treating"), React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "\n", React.createElement(InfoItem, null, "fine lines and wrinkles"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(InfoItem, null, React.createElement(_components.a, {
    href: "/stretch-marks"
  }, "sagging skin")), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(InfoItem, {
    noBorder: true
  }, "and roughness"), "\n"), "\n")), React.createElement(Pin, {
    id: "figure03",
    type: "end",
    height: 400,
    setTrigger: setScrollTrigger
  })), "\n", React.createElement(ResourcePanel, {
    id: "figure04",
    image: "figure04",
    imageAlt: "lorem ipsum",
    slug: props.data.mdx.frontmatter.slug,
    getTriggers: getScrollTriggers,
    slug: props.data.mdx.frontmatter.slug
  }, React.createElement(Pin, {
    id: "figure04",
    type: "start",
    height: 400,
    setTrigger: setScrollTrigger
  }), React.createElement(InfoNugget, null, React.createElement(Headline, {
    level: 2
  }, "Hemoglobin"), React.createElement(_components.p, null, "Hemoglobin is the target chromophore for"), React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "\n", React.createElement(InfoItem, null, "rosacea"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(InfoItem, null, "spider veins"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(InfoItem, null, "angiomas"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(InfoItem, {
    noBorder: true
  }, React.createElement(_components.a, {
    href: "/acne-diet"
  }, "acne")), "\n"), "\n")), React.createElement(Pin, {
    id: "figure04",
    type: "end",
    height: 400,
    setTrigger: setScrollTrigger
  })), "\n", React.createElement(CareInstructions, null, React.createElement(Headline, {
    level: 3
  }, "Treatment"), React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "\n", React.createElement(CareItem, {
    icon: "alert"
  }, "The professional using the laser should be well versed in settings that are used to treat darker skin types to help reduce the risk of pigmentary changes such as dark spots or light spots."), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(CareItem, {
    icon: "alert"
  }, "Lasers are frequently used to treat conditions such as acne, post inflammatory ", React.createElement(_components.a, {
    href: "/hyperpigmentation"
  }, "hyperpigmentation"), ", ", React.createElement(_components.a, {
    href: "/skin-bleaching-lightening#melasma"
  }, "melasma"), ", hair removal, tattoo removal and ", React.createElement(_components.a, {
    href: "/central-centrifugal-cicatricial-alopecia"
  }, "hair loss"), "."), "\n"), "\n")));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
